html, body {
  font-family: 'Noto Sans TC';
  height: auto;
  color: #323232;
}

button:focus, button:hover, a:focus, a:hover {
  box-shadow: none !important;
}

header h4 {
  font-size: 16px;
  color: #1db7bd;
  font-weight: 500;
  margin: 0 8px;
}

.username {
  font-size: 1.125rem;
}

.h3, h3 {
  font-size: calc(1rem + .6vw);
}

.cocontainer {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto
}

.mainpad {
  padding: 16px 8px 8px;
  width: 100%;
}

.user-photo {
  margin-right: 0;
}

.schname {
  max-width: 100px;
}

.comenu3 {
  display: block;
}

.comenu {
  display: none;
  /* height: 100%; */
  min-height: 100vh;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  width: 100%;
  top: 0;
  position: fixed;
  background: #32323224;
  left: 0;
  z-index: 99;
}

@keyframes slidein {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0;
  }
}

.lmenu {
  width: 256px;
  height: 100%;
  min-height: 100vh;
  background: #fff;
  padding-top: 10px;
  overflow-y: auto;
  animation: slidein 0.3s;
  z-index: 100;
}

.rmenu {
  width: calc(100% - 256px);
  min-height: 100vh;
}

.comain {
  width: 100%;
}

.cologo {
  max-width: 98px;
}

.coheader {
  font-size: 16px;
  color: #1db7bd;
  font-weight: 500;
  line-height: 1.67rem;
  margin: 0 8px;
}

.loginp .dropdown-toggle::after {
  font-size: 1.5rem;
  content: '';
}

.dshr8 {
  border-top: 1px solid #22cebb;
  width: 80%;
  margin: auto;
  margin-bottom: 12px;
}

.headactive {
  color: #1DB7BD;
}

.dshrs {
  border-top: 1px solid #e0e0e0;
  width: 100%;
}

.btntt {
  padding: 12px 24px;
}

button[aria-expanded='false'].dropdown-toggle::after, button[aria-expanded='true'].dropdown-toggle::after {
  border-top: none;
}

.accordion button[aria-expanded='false']::after {
  font-size: 24px;
  font-family: 'Material Icons';
  content: "expand_more";
  margin-left: auto;
  color: #a3a3a3;
}

.accordion button[aria-expanded='true']::after {
  font-size: 24px;
  font-family: 'Material Icons';
  content: "expand_less";
  margin-left: auto;
  color: #A3A3A3;
}

.dropdown-item {
  padding: 12px 56px;
}

.dropdown-item.active {
  color: #1DB7BD;
  background: #f6f6f6;
}

.dropdown-item:hover {
  background: #f6f6f6;
}

.btn-out-link {
  background-color: transparent;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}

.closeMenu {
  margin-top: 30px;
  margin-left: auto;
  margin-right: 30px;
}

.g14 {
  color: #828282;
  font-size: 14px;
}

.date20 {
  color: #323232;
  font-size: 20px;
  font-weight: 500;
}

.maxw680 {
  max-width: calc(100% - 200px);
}

.rowcol {
  display: flex;
  flex-direction: column;
}

a, a:active, a:focus, a:hover {
  color: #505050;
  text-decoration: none;
}

.nav-pills .nav-link {
  display: flex;
  color: #828282;
  background-color: #fff;
  align-items: center;
  word-break: keep-all;
  padding: 20px 8px;
  flex-wrap: wrap;
  justify-content: center;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #1DB7BD;
  background-color: #fff;
}

ul.nav {
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 80%;
}

.step-num {
  text-align: center;
  font-size: 18px;
  color: #828282;
  border-radius: 50%;
  border: 2px solid #828282;
  background: #fff;
  width: 32px;
  height: 32px;
  margin-left: 8px;
  margin-right: 8px;
}

.active .step-num {
  color: #fff;
  border-radius: 50%;
  border: 2px solid #1DB7BD;
  background: #1DB7BD;
  width: 32px;
  height: 32px;
}

.itemtitl {
  font-size: 18px;
  color: #1db7bd;
}

/* === book === */

.book {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 1.25rem;
}

.book-body {
  padding: 1.5rem 2rem;
}

/* === custom text === */

.text-orange-color {
  color: #ff9b3f;
}

.text-main-color {
  color: #1db7bd;
}

/* === date === */

.day-of-week {
  color: #828282;
}

.day-of-week.active {
  color: #ff9b3f;
}

.date {
  color: #828282;
  font-size: 24px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.date.active {
  background-color: #ff9b3f;
  color: #ffffff;
}

.border-custom {
  border-color: #1DB7BD !important;
}

.border-muted {
  border-color: #e0e0e0;
}

/* === chips === */

.chips {
  border: 1px solid #a3a3a3;
  color: #a3a3a3;
  padding: 8px 8px 8px 16px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* === tab === */

.tab-custom {
  position: relative;
  cursor: pointer;
  color: #828282;
}

.tab-custom.active {
  color: black;
}

.tab-highlight {
  z-index: -1;
  position: absolute;
  bottom: -3px;
  left: -6px;
  width: 80px;
  background-color: #e0e0e0;
}

.tab-highlight.active {
  background-color: #22cebb;
  /* width: 80px; */
}

.tab-highlight6 {
  z-index: -1;
  position: absolute;
  bottom: -3px;
  left: -6px;
  width: 100px;
  background-color: #e0e0e0;
}

.tab-highlight6.active {
  background-color: #22cebb;
  /* width: 100px; */
}

.tab-highlight3 {
  z-index: -1;
  position: absolute;
  bottom: -3px;
  left: -6px;
  width: 50px;
  background-color: #e0e0e0;
}

.tab-highlight3.active {
  background-color: #22cebb;
  /* width: 100px; */
}

#beginDate, #endDate {
  width: 144px;
}

/* === point === */

.point {}

.point::before {
  content: "•";
}

.point-yellow::before {
  color: #f2c94c;
}

.point-green::before {
  color: #1db7bd;
}

.point-blue::before {
  color: #2f80ed;
}

.form-custom-control {
  display: block;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 8px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.notify-field {
  border-radius: 8px;
  background-color: #f6f6f6;
}

/* === custom-button === */

.rounded-xl {
  border-radius: .5rem;
}

.border-lightgray {
  border-color: #e0e0e0 !important;
}

.btn-link-main, .btn-link-main:active, .btn-link-main:hover, .btn-link-main:focus {
  color: #1db7bd;
}

.btn-custom, .btn-custom:focus {
  /* border-radius: 8px; */
  border-color: #1DB7BD;
  background-color: #1DB7BD;
  color: #fff;
}

.btn-custom:hover, .btn-custom:active {
  border-color: #22cebb;
  background-color: #22cebb;
  color: #fff;
}

.btn-outline-custom, .btn-outline-custom:focus {
  border-color: #1DB7BD;
  color: #1DB7BD;
}

.btn-outline-custom:hover, .btn-outline-custom:active {
  border-color: #22cebb;
  color: #22cebb;
}

.btn-orange, .btn-orange:focus {
  border-color: #FF9B3F;
  background-color: #FF9B3F;
  color: #fff;
  border: 0;
}

.btn-orange:hover, .btn-orange:active {
  border-color: #f68e2d;
  background-color: #f68e2d;
  color: #fff;
}

.btn-outline-orange, .btn-outline-orange:focus {
  border-color: #ff9b3f;
  color: #ff9b3f;
}

.btn-outline-orange:hover, .btn-outline-orange:active {
  border-color: #f68e2d;
  color: #f68e2d;
}

.btn-outline-muted {
  color: #a3a3a3;
  border-color: #a3a3a3;
}

/* .btn-sm {
  width: 140px;
  height: 40px;
} */

/* .btn-lg {
  padding: 10px 16px;
  width: 220px;
} */

/* === custom-checkbox === */

/* The container */

.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 130px;
}

/* Hide the browser's default checkbox */

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkbox.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border: 2px solid black;
  border-radius: 3px;
}

/* When the checkbox is checked, add a blue background */

.custom-checkbox input:checked~.checkmark {
  border: none;
  background-color: #1DB7BD;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkbox.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.custom-checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* === Custom Radio Button === */

.custom-radio-btn {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.custom-radio-btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */

.radio.checkmark {
  position: absolute;
  /* top: 0; */
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #4f4f4f;
  /* background-color: #eee; */
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

/* .custom-radio-btn:hover input ~ .checkmark {
  background-color: #ccc;
} */

/* When the radio button is checked, add a blue background */

.custom-radio-btn input:checked~.checkmark {
  /* background-color: #1db7bd; */
  border: 2px solid #1db7bd;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.radio.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.custom-radio-btn input:checked~.checkmark:after {
  display: block;
  background-color: #1db7bd;
}

/* Style the indicator (dot/circle) */

.custom-radio-btn .checkmark:after {
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
}

.itemtitlbg {
  background: #1db7bd1f;
  padding: 10px 16px;
  border-radius: 8px;
}

.itemdate {
  word-break: keep-all;
}

.bta {
  padding: 4px 12px;
  word-break: keep-all;
  font-size: 16px;
}

.bta.active {
  background: #1DB7BD;
  border-radius: 4px;
  padding: 4px 12px;
  color: #fff;
}

.additem {
  background: #1DB7BD;
  text-align: center;
  color: #fff;
  margin-top: 40px;
}

.nextstep {
  width: 220px;
  margin: 40px auto;
  border-radius: 12px;
}

.prestep {
  border: 2px solid #1DB7BD;
  border-radius: 12px;
  text-align: center;
  padding: 10px 16px;
  color: #1DB7BD;
}

.step3tr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 16px;
  padding-top: 16px;
  align-items: center;
}

.step3thead {
  display: none;
}

.step3tr1 {
  display: flex;
  width: 100%;
}

.td1 {
  min-width: 30px;
}

.td2 {
  width: 10%;
  max-width: 90px;
  min-width: 70px;
  word-break: keep-all;
  color: #323232;
}

.td3 {
  width: auto;
  max-width: none;
  min-width: 88px;
  word-break: keep-all;
  color: #323232;
  flex-grow: 1;
  text-align: right;
  font-size: 14px;
}

.td4 {
  width: 96%;
  max-width: none;
  color: #828282;
  word-break: break-all;
}

.td5 {
  width: 100%;
  max-width: none;
}

.yday {
  text-align: left;
  width: 100%;
  font-size: 14px;
}

.ml3, .mr3 {
  margin-left: 12px;
  margin-right: 12px;
}

.yearmon {
  max-width: 160px;
}

.ctitle {
  font-size: 24px;
}

.font-h4 {
  font-size: 18px;
}

.font-h5 {
  font-size: 18px;
}

#searcht td {
  width: 100px;
  padding: 4px 0;
  font-size: 14px;
}

#searcht td.createdate {
  width: 50%;
  color: #828282;
}

#searcht td.tdcontent {
  width: 100%;
  color: #828282;
}

#searcht td[scope="col"] {
  padding: 8px 0;
}

.hsearch {
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid;
  padding: 8px 16px;
}

.w-160-140 {
  width: 136px;
}

@media (max-width: 991px) {
  .sbt-log {
    position: relative;
  }

  .collapse.show {
    padding: 0;
    position: initial;
    background: none;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1060px) {
  .navbar>.container {
    justify-content: space-between;
  }
}

@media (min-width:576px) {
  .cocontainer {
    max-width: 540px;
  }
}

@media (min-width:768px) {
  .cocontainer {
    max-width: 720px;
  }

  .font-h4 {
    font-size: 24px;
  }

  .font-h5 {
    font-size: 20px;
  }

  header h4 {
    font-size: 24px;
    margin: 0 16px;
  }

  .cologo {
    max-width: 114px;
  }

  .td1 {
    width: 10%;
    max-width: 80px;
    min-width: 50px;
    word-break: keep-all;
    color: #323232;
  }

  .td3 {
    width: 10%;
    max-width: 96px;
    min-width: 88px;
    word-break: keep-all;
    color: #323232;
    text-align: left;
    font-size: 16px;
  }

  .td4 {
    width: 35%;
    max-width: 300px;
  }

  .td5 {
    width: 40%;
    max-width: 300px;
  }

  .yday {
    display: none;
  }

  .step3tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 16px;
    padding-top: 16px;
    align-items: center;
  }

  .step3thead {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 12px;
    padding-top: 12px;
  }

  .step3tr1 {
    display: flex;
    width: 35%;
  }

  .tab-highlight {
    width: 90px;
  }

  .tab-highlight:active {
    /* width: 90px; */
  }

  .tab-highlight6 {
    width: 110px;
  }

  .tab-highlight6:active {
    /* width: 110px; */
  }

  .tab-highlight3 {
    width: 55px;
  }

  #beginDate, #endDate {
    width: 160px;
  }

  #searcht td.createdate, #searcht td.tdcontent {
    width: 100px;
    color: #000;
  }

  #searcht td {
    padding: 24px 0;
    font-size: 16px;
  }

  .w-160-140 {
    width: 160px;
  }
}

@media (min-width:992px) {
  .cocontainer {
    max-width: 960px;
  }

  .cologo {
    max-width: 164px;
  }

  .comenu3 {
    display: none;
  }

  .comenu {
    display: block !important;
    width: 256px;
    position: inherit;
    background: #fff;
    animation: none;
  }

  .lmenu {
    width: 100%;
  }

  .comain {
    width: calc(100% - 256px);
  }

  .mainpad {
    padding: 16px 16px 8px;
    width: calc(100% - 256px);
  }
}

@media (min-width:1200px) {
  .cocontainer, .container {
    max-width: 1140px;
  }
}

@media (min-width:1400px) {
  .cocontainer, .container {
    max-width: 1320px;
  }
}

@media (max-width: 576px) {
  .date20 {
    font-size: 14px;
  }

  .rowcol {
    flex-direction: row;
  }

  .bta {
    padding: 8px 8px;
    font-size: 14px;
  }

  .bta.active {
    padding: 8px 8px;
  }

  .ml3 {
    margin-left: 0;
    margin-right: 8px;
  }

  .mr3 {
    margin-left: 8px;
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .maxw680 {
    max-width: 520px;
  }

  ul.nav {
    max-width: 100%;
  }

  .yearmon {
    display: none;
  }

  .ctitle {
    font-size: 18px;
  }
}

[type=button]:not(:disabled), button:not(:disabled) {
  cursor: auto;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  /* width: 30px;
  padding: 0px; */
  margin-left: 0px;
}

.qaqb {
  border: none;
  background: #f6f6f6;
}

.qaqb.collapsed {
  background: #fff;
}

.qaq {
  background: none;
  text-align: left;
  font-size: 18px;
  background: #f6f6f6;
  color: #1db7bd;
  text-align: justify;
}

.collapsed .qaq {
  background: #fff;
  color: #323232;
}

.qaopen.collapse.show, .qaopen {
  background: #fbfbfb;
}

.qaopen .card-body {
  border-left: 1px solid #1db7bd;
  margin-left: 30px;
  text-align: justify;
}

.accordion {
  max-width: 730px;
}

.mw-500 {
  max-width: 500px;
}

.theme-default .bs-datepicker-head {
  background-color: #fff !important;
}

.bs-datepicker-head button {
  background: none !important;
  color: #000 !important;
}

.bs-datepicker-head button[disabled], .bs-datepicker-head button[disabled]:hover, .bs-datepicker-head button[disabled]:active {
  color: #7a7a7a !important;
}

.bs-datepicker-head button:hover, .bs-datepicker-head button:active {
  background: rgb(238 238 238) !important;
}

.bs-datepicker-head {
  text-align: center !important;
}

.theme-default .bs-datepicker-body table td span.selected, .theme-default .bs-datepicker-body table td.selected span, .theme-default .bs-datepicker-body table td span[class*="select-"]:after, .theme-default .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #ff9b3f !important;
  color: #fff;
}

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span, .bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #ffeddc !important;
}

.bs-datepicker-head, .bs-datepicker {
  border-radius: 0.75rem !important;
}

.bs-datepicker-body table th, .bs-datepicker-body table td {
  font-size: 16px !important;
  color: #000000 !important;
}

.bs-datepicker-body table td span {
  font-size: 14px !important;
}
