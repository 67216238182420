/* You can add global styles to this file, and also import other style files */

@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "./assets/css/home.css";
@import "./assets/css/coocontace.css";
@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';

// custom ngx datepicker
.bs-datepicker-container {
    padding: 0 !important;
}