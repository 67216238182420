html, body {
  /* font-family: "Noto Sans TC", "新細明體"; */
  /* font-family: Arial, "文泉驛正黑", "WenQuanYi Zen Hei", "儷黑 Pro", "LiHei Pro", "微軟正黑體", "Microsoft JhengHei", "標楷體", DFKai-SB, sans-serif; */
  font-family: Arial, 'Noto Sans TC', '文泉驛正黑', 'WenQuanYi Zen Hei', '儷黑 Pro', 'LiHei Pro', '微軟正黑體', 'Microsoft JhengHei', sans-serif;
}

body {
  font-size: 1rem;
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 1400px;
}

.bbg {
  background: url(/assets/img/2021/bg.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: inherit;
}

a, a:active, a:focus, a:hover {
  color: #3081ed;
  text-decoration: none;
}

:focus {
  outline: none!important;
}

.content-slider {
  width: 90%;
  margin: auto;
  max-width: 1150px;
}

.sliderA {
  display: flex;
  margin: 0;
  padding-top: 180px;
  justify-content: center;
}

#svc, #news {
  background: #ffffff;
}

.bt-slog {
  display: flex;
  margin: 20px 0 0 0;
  justify-content: center;
  z-index: 2;
  position: relative;
}

.d-item {
  display: none;
}

.d-item-rwd {
  display: block;
}

.navbar>.container {
  max-width: 1140px;
}

.user-photo {
  width: 54px;
  height: 54px;
  border-radius: 50%;
	border: 2px solid #45d5c6;
  padding: 2px;
}

.dsul {
  padding-left: 24px;
}

.mxs-4 {
  margin-left: 24px;
  margin-right: 24px;
}

/* .border-bottom {
  border-bottom: 1px solid #22CEBB!important;
} */

@media (min-width: 1200px) {
  .navbar>.container {
    max-width: 1140px;
  }
}

@media (min-width: 992px) {
  .navbar>.container {
    max-width: none;
  }
  .d-item {
    display: block;
  }
  .d-item-rwd {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar>.container {
    max-width: none;
  }
}

@media (min-width: 576px) {
  .navbar>.container {
    max-width: 1290px;
  }
}

@media (max-width:480px) {
  .content-slider {
    width: 100%;
  }
  body {
    margin: 0;
  }
}

@media (max-width: 992px) {
  .navbar>.container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .user-photo {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 576px) {
  .navbar>.container {
    padding-right: 0px;
    padding-left: 0px;
  }
  .user-photo {
    width: 36px;
    height: 36px;
  }
  .point.mxs-4 {
    margin-left: 12px;
    margin-right: 12px;
  }
}

.menuUser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
}

.fs18 {
  font-size: 1.125rem;
  color: #323232;
}

.center .slick-slide:not(.slick-center) {
  /* transform: translate(-60px, 0px) scale(0.85); */
  z-index: 1;
}

.center .slick-center {
  z-index: 3;
}

.center .slick-slide.slick-cloned~.slick-slide:not(.slick-center) {
  transform: translate(5%, 10px) scale(0.9);
  z-index: 1;
  opacity: .5;
}

.center .slick-prev {
  left: 2% !important;
  z-index: 9;
}

.center .slick-next {
  right: 2% !important;
}

.center .slick-active.slick-center+.slick-slide+.slick-slide {
  z-index: 1;
}

.center .slick-active.slick-center+.slick-slide, .center .slick-center+.slick-cloned {
  transform: translate(-5%, 10px) scale(0.9)!important;
  opacity: .5;
}

/*
 */

.single-item {
  width: 812px;
  height: 408px;
  /* margin-right: auto; */
  margin-top: 150px;
  margin-bottom: 30px;
}

.single-item .slick-dots li button {
  display: block;
  width: 120px;
  height: 8px;
  padding: 0;
  border: none;
  border-radius: 1%;
  background-color: #1DB7BD;
  text-indent: -9999px;
  opacity: .25;
  border-radius: 10px;
}

.single-item .slick-dots li {
  width: auto;
  height: 20px;
  /* margin: 0 10px; */
}

.single-item li.slick-active button {
  background-color: #1DB7BD;
  opacity: .75;
}

.single-item .slick-dots {
  position: initial;
  margin-top: -120px;
  /* display: flex; */
  float: right;
  margin-right: 5%;
  width: auto;
}

.slider-title {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -.05rem;
  word-break: keep-all;
  overflow: hidden;
}

.btitle {
  font-size: 40px;
}

.single-item .slider-txt {
  /* justify-content: center; */
  /* margin-left: -15px; */
  min-width: 300px;
  /* margin-top: 1.5rem; */
}

.slider-txt.align-self-center {
  height: 320px;
}

.sitem {
  padding: 10px 10px 10px 0;
  font-weight: 500;
  /* margin: 5px; */
}

.slider-icon {
  display: inline!important;
  vertical-align: bottom!important;
  width: 24px!important;
  margin-right: 10px;
}

.slider-item {
  padding: 4px 0;
  font-size: 16px;
  color: #000000;
  display: block;
}

.slider-item:hover, .slider-item:active, .slider-item:focus {
  color: #000000;
  text-decoration: none;
}

.center .slick-slide.slick-current.slick-active.slick-center {
  box-shadow: 0 0 10px 1px #5656567a;
  margin: 10px 0;
}

.single-item .slider-png {
  width: 512px;
  height: 408px;
}

.single-item .slider-png img {
  width: 438px;
  margin: auto;
}

.itembox {
  margin-top: 20px;
}

.iphone {
  display: flex;
  margin: 220px 0 -50px 0;
  justify-content: center;
}

.navbar-brand img {
  max-height: 40px;
}

.username {
  display: block;
}

.dropdown .btn {
  min-width: auto;
  padding-left: 8px;
}

.dropdown>.btn:hover, .dropdown>.btn:focus, .dropdown>.btn:active {
  background-image: none;
}

.downshow {
  display: none;
}

.closeMenu {
  margin-top: -20px;
  margin-bottom: 30px;
  display: none;
}

.closeMenu:hover {
  /* background: #ddd; */
  cursor: pointer;
}

.dstitle {
  font-size: 1.125rem;
  font-weight: 500;
  margin: 24px 20px 16px 12px;
}

.dshr {
  border-top: 1px solid #E0E0E0;
}

.dshrs {
  border-top: 1px solid #22cebb;
  width: 168px;
}

.dsli {
  /* list-style-type: circle; */
  list-style-image: url(/assets/img/2021/dotsels.svg);
}

.dsli:hover, .dsli:active, .dsli:focus {
  /* list-style-type: circle; */
  list-style-image: url(/assets/img/2021/dotslick.svg);
}

.dsli {
  margin: 16px 0;
}

.dsli a {
  color: #000000;
  vertical-align: middle;
  margin-left: 10px;
}

.dsli a:hover, .dsli a:active, .dsli a:focus {
  color: #1DB7BD;
}

.schname {
  max-width: 80px;
  white-space: initial;
  text-align: left;
  color: #323232;
  font-size: 0.8rem;
}

.form-check-input {
  margin-top: 32px;
}

.itemSch {
  font-size: 1rem;
  color: #323232;
}

/* .dropdown-menu li:hover, .dropdown-menu li:active, .dropdown-menu li:focus, .dropdown-menu li:checked, input[type='radio']:checked+label, .dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover {
  background: #f6f6f6;
} */

.dropdown-toggle::after {
  color: #A3A3A3;
}

/* input[type='radio']:checked+label span */

button[aria-expanded='true'].dropdown-toggle::after, button[aria-expanded='false'].dropdown-toggle::after {
  font-size: 1.714286em;
  line-height: 0.583333em;
  vertical-align: -0.302198em;
  font-family: 'Material Icons';
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  content: "arrow_drop_up";
  display: inline-block;
  margin-right: -5px;
  margin-left: 5px;
  vertical-align: top;
}

button[aria-expanded='false'].dropdown-toggle::after {
  content: "arrow_drop_down";
}

.d-item-rwd button[aria-expanded='false'].dropdown-toggle::after, .d-item-rwd button[aria-expanded='true'].dropdown-toggle::after {
  margin-left: 13px;
}

.loginp.open.show .dropdown-toggle::after, .loginp .dropdown-toggle::after {
  font-size: 2rem;
  content: "arrow_drop_down";
  color: #1DB7BD;
  margin-left: 8px;
}

.loginp.open.show .dropdown-toggle::after {
  content: "arrow_drop_up";
}

a.drop-link {
  text-decoration: none;
  color: #323232;
  /* font-size: 1.125rem; */
}

.close-img {
  margin-right: 16px;
  width: 15px;
  margin-top: 3px;
  cursor: pointer;
}

.itemname {
  font-weight: 600;
  font-size: 1.25rem;
}

.dmenu {
  width: 250px;
  background: #fff;
  padding: 40px 30px;
  overflow-y: auto;
  height: 100vh;
}

/* input[type="radio"] {
  display: none;
}

input[type='radio']+label span {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  background-color: #ffffff;
  background: url(/assets/img/2021/Radiobutton.png);
  background-size: 24px;
  margin-right: 10px;
  margin-top: 10px;
}

input[type='radio']:checked+label span {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  background: url(/assets/img/2021/Radiobutton_checked.png);
  background-size: 24px;
}

.d-item input[type='radio']+label span, .sizerwd input[type='radio']+label span {
  margin-top: 0px;
} */

.sizelabel {
  display: flex;
}

.open.show>.btn.dropdown-toggle, .d-item .show>.btn.dropdown-toggle, .d-item-rwd .show>.btn.dropdown-toggle {
  background-image: none;
}

.dropdown>.dropdown-menu-right:not([x-placement])::before, .dropdown-menu.show::before {
  border-radius: 12px;
}

.dropdown.loginp>.dropdown-menu-right:not([x-placement])::before, .dropdown-menu.loginp.show::before {
  background-color: #ffffff;
  box-shadow: none;
}

.d-item .dropdown-menu.show {
  width: 128px;
  text-align: center;
}

.droplang .dropdown-menu.show {
  min-width: 168px;
  text-align: center;
  width: auto;
}

.scrolly {
  max-height: 100vh;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 3px rgb(0 0 0 / 12%), 0 4px 15px 0 rgb(0 0 0 / 20%);
}

/* .sizerwd.dropdown-menu {
  margin-left:inherit ;
}

.sizerwd.dropdown-menu::before {
  background: #f6f6f6;
  box-shadow: none;
  width: 168px;
  border-radius: 0px;
} */

.dropTitle {
  display: block;
  font-weight: 600;
  height: 56px;
  line-height: 56px;
}

@media (max-width:991px) {
  .sbt-log {
    position: absolute;
    right: 24px;
    top: 8px;
  }
  .loginp {
    top: auto;
  }
  .collapse.show {
    /* padding: 40px; */
    position: absolute;
    /* top: 0;
    left: 0; */
    background: #32323250;
    /* overflow: auto; */
    /* overflow-y: auto; */
    /* width: 300px; */
    /* inset: 0 auto auto 0!important; */
    height: 100vh;
    width: 100%;
    top: 0!important;
    left: 0!important;
  }
  .downshow {
    display: block;
  }
  .navbar .dropdown-menu {
    width: auto;
  }
  .closeMenu {
    display: block;
  }
  .sizerwd.dropdown-menu {
    margin-left: 8px;
    position: inherit;
    background: #f6f6f6;
    width: 168px;
  }
  .sizerwd.dropdown-menu::before {
    background: #f6f6f6;
    box-shadow: none;
    width: 168px;
    border-radius: 0px;
  }
  .dropTitle {
    display: none;
  }
  /* .schname {
    display: none;
  } */
  /* .dropdown-menu.show > * {
    display: contents;
    background: #ddd;
    margin-left: 40px;
    width: 150px;
} */
}

@media (max-width: 1060px) {
  .navbar-brand img {
    max-height: 34px;
  }
  .btn-user {
    padding: 0;
  }
  .navbar>.container {
    justify-content: end;
  }
}

@media (max-width: 576px) {
  .navbar-brand img {
    max-height: 24px;
  }
  .username, .schname, .dropdown-toggle::after {
    display: none;
  }
  .sbt-log {
    /* position: revert; */
    display: contents;
  }
  .navbar>.container {
    justify-content: space-between;
  }
  .btn:focus, .btn:hover, .show>.btn.dropdown-toggle {
    background-image: none;
  }
  .scrolly {
    right: 24px!important;
    top: 80% !important;
  }
}

@media (max-width:480px) {
  .center .slick-slide.slick-current.slick-active.slick-center {
    box-shadow: none;
    margin: 0;
  }
  .slider-item {
    font-size: 14px;
  }
}

@media (max-width:1130px) {
  .slider1.d-flex, .slider2.d-flex {
    flex-direction: column;
  }
  .single-item {
    width: 512px;
    height: 700px;
    margin-top: 50px;
  }
  .single-item .slick-dots {
    margin-top: -70px;
    display: block;
    width: inherit;
    float: initial;
  }
  .iphone {
    margin: 140px 0 70px 0;
  }
}

@media (max-width:827px) {
  .title1 {
    margin-right: auto;
  }
}

@media (max-width:780px) {
  .single-item {
    margin-top: -8px;
    width: 100%;
    height: 600px;
  }
  .single-item .slider-png, .single-item .slider-png img {
    width: 100%;
    max-width: 400px;
  }
  .single-item .slider-png {
    width: 70%;
    height: 370px;
    margin-left: auto;
  }
  .slider-txt.align-self-center {
    margin-right: auto;
    margin-top: -70px;
  }
  .single-item .slick-dots {
    /* margin-top: -110px; */
    display: flex;
  }
}

.th1 {
  font-size: 55px;
  line-height: 72px;
  font-weight: 700;
  display: block;
}

.th1s {
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  display: none;
}

@media (max-width:560px) {
  .single-item {
    height: 400px;
    width: 100%;
    z-index: 1;
  }
  .single-item .slider-png img {
    width: 100%;
  }
  .single-item .slider-png {
    height: 280px;
  }
  .th1 {
    display: none;
  }
  .th1s {
    display: block;
  }
  .slider-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -.05rem;
  }
  .btitle {
    font-size: 24px;
  }
  .d-flex.slider-txt {
    margin-top: 0.5rem;
  }
  .single-item .slick-dots {
    margin-top: -120px;
  }
  .single-item .slick-dots li button {
    width: 73px;
    height: 4px;
  }
  .sliderA {
    padding-top: 100px;
  }
}

.bt-log {
  background: #1DB7BD;
  border-radius: 12px;
  border: 0px;
  color: #fff;
  font-size: 1.25rem;
  padding: 12px 44px;
  font-weight: 500;
}

.bt-login {
  border-radius: 12px;
  border: 0px;
  background-image: url(/assets/img/2021/unloginbt.svg);
  width: 168px;
  height: 48px;
}

@media (max-width: 576px) {
  .bt-login {
    border-radius: 50px;
    background-image: url(/assets/img/2021/unlogin.png);
    background-size: 36px;
    width: 36px;
    height: 36px;
  }
}

.btntt {
  background: #ffffff;
  border-radius: 25px;
  border: 0px;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  padding: 10px 8px;
  font-weight: 500;
  /* margin-right: 16px; */
}

.btntt:hover, .btntt:hover {
  /* box-shadow: 0 0 10px #1db7bd40; */
  background-image: none;
}

.bt-sign {
  background: #FFFFFF;
  border: 2px solid #1DB7BD;
  box-sizing: border-box;
  border-radius: 12px;
  color: #1DB7BD;
  font-size: 1.25rem;
  padding: 12px 22px;
  font-weight: 500;
  margin-left: 16px;
}

.bt-log:hover, .bt-sign:hover {
  box-shadow: 0 0 10px #1db7bd40;
}

.bt-login:hover, .bt-sign:hover {
  box-shadow: 0 0 10px #1db7bd40;
}

.btn:active {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}

.bt-covid {
  background: #FFFFFF;
  border: 2px solid #FF9B3F;
  box-sizing: border-box;
  border-radius: 12px;
  color: #FF9B3F;
  font-size: 1.25rem;
  padding: 12px 22px;
  font-weight: 500;
  margin-right: auto;
  word-break: keep-all;
}

.bt-covid:hover, .bt-covid:active, .bt-covid:focus {
  box-shadow: 0 0 10px #f87800;
  color: #FF9B3F;
  text-decoration: none;
}

@media (max-width:1020px) {
  .bt-covid {
    font-size: 1rem;
    text-align: center!important;
    max-width: 200px;
  }
}

@media (max-width:480px) {
  .bt-log {
    font-size: 1rem;
    padding: 8px 36px;
  }
  .bt-sign {
    font-size: 1rem;
    padding: 8px 18px;
  }
  .iphone {
    margin-top: 78px;
  }
}

.ipic {
  width: 510px;
  text-align: center;
}

.i3pic {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 640px;
  height: 700px;
}

.itext {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 36px;
  align-self: flex-end;
  padding: 30px;
}

.app {
  align-self: end;
  margin: 50px auto;
}

.app2 {
  display: none;
}

.pic3 {
  align-self: flex-end;
}

.app img {
  margin: 0 20px;
}

.tran {
  justify-content: center;
  margin-bottom: 80px;
}

.tran-txt {
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0em;
  align-self: center;
  margin-left: 24px;
}

@media (max-width:1180px) {
  .i3pic {
    width: 720px;
  }
}

@media (max-width:1130px) {
  .i3pic {
    height: auto;
    flex-direction: column;
    justify-content: flex-end;
  }
  .app {
    display: none;
  }
  .app2 {
    display: block;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 66px;
  }
  .ipic, .i3pic {
    width: auto;
  }
  .itext {
    align-self: auto;
  }
  .tran {
    margin-bottom: 50px;
  }
}

.ipic img {
  width: 270px;
}

.app2 img {
  margin: 0 20px;
}

@media (max-width:680px) {
  .ipic img {
    width: 170px;
  }
  .i3pic img {
    width: 240px;
  }
  .itext {
    font-size: 0.875rem;
    line-height: 18px;
  }
  .app2 img {
    width: 150px;
    margin: 0 4px;
  }
  .tran {
    margin-bottom: 24px;
  }
}

@media (max-width:480px) {
  .i3pic {
    height: auto;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-left: -120px;
  }
  .pic3 {
    align-self: flex-end;
    margin-top: 150px;
  }
  .itext {
    margin-left: 140px;
    margin-top: 80px;
    padding: 0;
  }
  .app2 {
    margin-top: -30px;
    margin-bottom: 24px;
  }
  .app2 img {
    width: 130px;
  }
  .tran-txt {
    font-size: 0.875rem;
    line-height: 18px;
    margin-left: 8px;
  }
}

.has-search .form-control {
  padding-left: 50px;
  font-size: 1.125rem;
}

.has-search {
  width: 560px;
  margin: auto;
}

.form-control-feedback {
  padding-top: 10px;
  padding-left: 20px;
}

.has-search .form-control {
  border-radius: 50px;
  height: 56px;
  border-width: thin;
}

.form-control:focus, .form-control:hover {
  border-color: rgba(0, 0, 0, 0.42);
  box-shadow: none;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.sear-title, .sear-titleA {
  font-size: 2rem;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 80px;
  margin-bottom: 40px;
}

.sear-titleA {
  padding-top: 150px;
}

.sear-key-title {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #A3A3A3;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.sear-key {
  color: #2f80ed;
}

.sec-title {
  padding: 32px 0 24px 24px;
  font-size: 1.25rem;
  line-height: 24px;
  font-weight: 600;
}

@media (max-width:768px) {
  .sear-title {
    font-size: 1.5rem;
  }
  .sear-titleA {
    padding-top: 80px;
    font-size: 1.5rem;
  }
}

@media (max-width:680px) {
  .sear-title {
    font-size: 1.125rem;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  .sear-titleA {
    font-size: 1.125rem;
    padding-top: 80px;
    margin-bottom: 20px;
  }
  .sec-title {
    font-size: 1rem;
  }
  .has-search {
    width: 90%;
  }
  .sear-key-title {
    font-size: 0.875rem;
  }
  .has-search .form-control {
    border-radius: 50px;
    height: 48px;
  }
  .form-control-feedback {
    padding-top: 5px;
  }
}

.responsive img {
  /* padding: 0 10px; */
  box-shadow: 16px 34px #FF9B3F;
  border-radius: 12px;
}

.btslider img {
  width: 100%;
  max-width: 48px;
  margin: auto;
  margin-bottom: 8px;
}

.responsive .slick-dots li.slick-active button:before {
  opacity: 1;
  content: url(/assets/img/2021/dotSlickLineorg.svg);
}

.responsive .slick-dots li.slick-active, .responsive .slick-dots li.slick-active button {
  width: 54px;
}

.responsive .slick-dots li button:before {
  opacity: 1;
  content: url(/assets/img/2021/dotSlickorg.svg);
  width: 20px;
}

.responsive .slick-dots li button {
  width: 20px;
}

.responsive .slick-dots li, .center .slick-dots li, .btslider .slick-dots li {
  margin: 0!important;
}

.imgbg {
  position: absolute;
  width: 254px;
  height: 160px;
  left: 16px;
  top: 40px;
  background: #FF9B3F;
  opacity: 0.85;
  border-radius: 8px;
}

.responsive .slick-slide {
  padding: 20px;
}

.btslider .slick-slide {
  padding: 18px 10px 20px 10px;
}

.responsive .slick-next {
  right: -40px;
}

.btslider .slick-next {
  right: -7%;
}

.btslider .slick-prev {
  left: -7%;
}

@media(max-width:960px) {
  .btslider .slick-next {
    right: -8%;
  }
  .btslider .slick-prev {
    left: -8%;
  }
}

.resp-slide {
  /* width: 270px;
    height: 200px; */
  position: relative;
}

.respi {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 13px 20px;
  margin: -3px;
}

.respi:hover {
  border: 1px solid #22CEBB;
  box-sizing: border-box;
  border-radius: 8px;
}

a.resp-slide:hover {
  color: #000000;
  text-decoration: none;
}

a.resp-slide {
  color: #000000;
}

.responsive .img-txt {
  margin-left: 30px;
  font-size: 1.125rem;
  ;
  line-height: 32px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  margin-right: -15px;
}

.btslider .img-txt {
  font-size: 1.25rem;
  line-height: 24px;
  text-align: center;
  white-space: inherit;
  /* width: 5rem; */
  word-break: keep-all;
  margin-left: -20px;
  margin-right: -20px;
}

@media (max-width:630px) {
  .btslider .img-txt {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .btslider img {
    max-width: 44px;
  }
}

/* Tabs */

.content-slider .nav-tabs .nav-link.active::before {
  opacity: 0;
}

.nav-tabs {
  box-shadow: none;
}

.content-slider .nav-tabs {
  border-bottom: none;
}

.content-slider .nav-tabs .nav-item {
  margin: 0 20px 30px;
}

.content-slider .nav-tabs .nav-item.show .nav-link, .content-slider .nav-tabs .nav-link.active {
  color: #000000!important;
  background-color: transparent!important;
  border-color: transparent!important;
  font-weight: 500;
  font-size: 1.25rem;
}

/* .nav-tabs .nav-link:focus, */

.content-slider .nav-tabs .nav-link:hover:not(.active) {
  /* border-color: transparent!important; */
  color: #828282;
}

.content-slider .nav-tabs .nav-link:active, .content-slider .nav-tabs .nav-link:focus, .content-slider .nav-tabs .nav-link:hover {
  background-color: transparent;
}

.content-slider .nav-tabs .nav-link {
  border: none!important;
  color: #828282;
  font-weight: 500;
  font-size: 1.25rem;
}

.camera-list, .count-list, .news-list, .svc-list {
  display: flex;
  flex-wrap: wrap;
  /* height: 220px; */
  /* overflow: hidden; */
}

#home, #menu1 {
  overflow: hidden;
  height: 228px;
}

@media (max-width:450px) {
  #home, #menu1 {
    height: 200px;
  }
}

/* .svc-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: calc(25% - 20px);
  margin-right: 20px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 500;
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(215, 215, 215);
  border-image: initial;
  padding: 16px;
  border-radius: 8px;
} */

.svc-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: calc(25% - 20px);
  margin-top: 8px;
  margin-right: 20px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 400;
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(215, 215, 215);
  border-image: initial;
  padding: 16px;
  border-radius: 8px;
}

.svc-item:hover {
  background: #fff;
  box-shadow: 0 0 10px #0002;
  border: 0;
  text-decoration: none;
  color: #323232;
}

/* *,
::after,
::before {
    box-sizing: inherit!important;
} */

.svc-item img {
  max-width: 60px;
  max-height: 60px;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(215, 215, 215);
  border-image: initial;
  border-radius: 50%;
  margin-right: 0;
}

.svc-item span {
  width: 100%;
  margin-left: 16px;
  /* white-space: nowrap; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.nav-link+.backg4 {
  width: 5rem;
  height: 16px;
  background-color: #e1e1e19c;
  margin-top: -25px;
  margin-left: 5px;
}

.nav-link.active+.backg4 {
  display: block;
  width: 5rem;
  height: 16px;
  background-color: #22cebb9c;
  margin-top: -25px;
  margin-left: 5px;
}

.nav-link+.backg5 {
  width: 6.5rem;
  height: 16px;
  background-color: #e1e1e19c;
  margin-top: -25px;
  margin-left: 5px;
}

.nav-link.active+.backg5 {
  display: block;
  width: 6.5rem;
  height: 16px;
  background-color: #22cebb9c;
  margin-top: -25px;
  margin-left: 5px;
}

.nav-link+.backg6 {
  width: 7.5rem;
  height: 16px;
  background-color: #e1e1e19c;
  margin-top: -25px;
  margin-left: 5px;
}

.nav-link.active+.backg6 {
  display: block;
  width: 7.5rem;
  height: 16px;
  background-color: #22cebb9c;
  margin-top: -25px;
  margin-left: 5px;
}

.nav-link+.backg7 {
  width: 8.5rem;
  height: 16px;
  background-color: #e1e1e19c;
  margin-top: -25px;
  margin-left: 5px;
}

.nav-link.active+.backg7 {
  display: block;
  width: 8.5rem;
  height: 16px;
  background-color: #22cebb9c;
  margin-top: -25px;
  margin-left: 5px;
}

/* @media (max-width: 450px) {
  .nav-link.active+.backg {
    width: 110px;
  }
} */

.sch-news .nav-link.active+.backg {
  width: 120px;
}

.bt-more button {
  background: #FFFFFF;
  border: 2px solid #1DB7BD;
  box-sizing: border-box;
  border-radius: 12px;
  width: 240px;
  height: 48px;
  font-size: 1.25rem;
  color: #1DB7BD;
}

.bt-more button:hover {
  box-shadow: 0 0 10px #1db7bd40;
}

.abtn {
  background: #FFFFFF;
  border: 2px solid #1DB7BD;
  box-sizing: border-box;
  border-radius: 12px;
  /* width: 240px;
  height: 48px; */
  padding: 0.8rem 5rem;
  font-size: 1.25rem;
  color: #1DB7BD;
  word-break: keep-all;
}

.abtn:hover {
  box-shadow: 0 0 10px #1db7bd40;
  color: #1DB7BD;
  text-decoration: none;
}

#msg, #subs {
  /* font-size: 1.25rem; */
  margin: 0 12px;
}

#msg .row, #subs .row, .news .row {
  padding: 8px 24px;
  cursor: pointer;
}

.sch-news .content-slider .nav-tabs .nav-item {
  margin: 16px 24px;
}

.subs-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 8px;
}

.hr1 {
  width: 80%;
  background: #22CEBB;
  margin: 80px auto 0;
  max-width: 956px;
}

.hr2 {
  background: #22CEBB;
  margin: 0 24px 16px;
}

@media (max-width: 1279px) {
  .camera-item, .news-item, .svc-item {
    font-size: 1.125rem;
  }
}

@media (max-width: 991px) {
  .count-item:nth-child(5n+5), .svc-item:nth-child(5n+5), .svc-item:nth-child(2n+2), .count-item:nth-child(4n+4), .svc-item:nth-child(4n+4) {
    margin-right: 20px;
  }
  .svc-item {
    width: calc(33% - 18px);
  }
  .bt-more button {
    font-size: 1.125rem;
  }
  .abtn {
    font-size: 1.125rem;
  }
}

@media (max-width: 767px) {
  .camera-item, .news-item, .svc-item {
    width: calc(50% - 20px);
    font-size: 1rem;
  }
  .sch-news .nav-link.active+.backg {
    /* width: 90px; */
  }
  .responsive .img-txt {
    font-size: 1rem;
  }
  #msg, #subs {
    font-size: 0.875rem;
  }
  #msg .row, #subs .row, .news .row {
    padding: 4px 24px;
    cursor: pointer;
  }
  .abtn {
    font-size: 1rem;
    padding: 0.5rem 3rem;
  }

}

@media (max-width: 450px) {
  #home, #menu1 {
    padding-right: 0;
  }
  .content-slider .nav-tabs .nav-link {
    font-size: 1rem;
  }
  .svc-item {
    padding: 8px;
  }
  .svc-item img {
    max-width: 48px;
    margin: 8px 8px 8px 0;
  }
  .svc-item span {
    margin: 0;
  }
  .content-slider .nav-tabs .nav-item, .sch-news .content-slider .nav-tabs .nav-item {
    margin-left: 20px;
    margin-right: 12px;
  }
  .content-slider .nav-tabs .nav-item.show .nav-link, .content-slider .nav-tabs .nav-link.active {
    font-size: 1rem;
  }
  .nav-link.active+.backg4, .nav-link+.backg4 {
    width: 4rem;
  }
  .nav-link.active+.backg5, .nav-link+.backg5 {
    width: 5rem;
  }
  .nav-link.active+.backg6, .nav-link+.backg6 {
    width: 6rem;
  }
  .nav-link.active+.backg7, .nav-link+.backg7 {
    width: 7rem;
  }
  .bt-more button {
    font-size: 1rem;
    width: 168px;
    height: 40px;
  }
  .hr2 {
    margin: 0 24px 16px;
  }
  #msg .row .col-md-8, #msg .row .col-md-4, #subs .row .col-lg-8, #subs .row .col-lg-4 {
    padding: 0 8px;
  }
}

.bt-more {
  text-align: center;
  padding: 36px;
}

.news {
  width: 80%;
  /* height: 280px; */
  border: 1px solid #A3A3A3;
  box-sizing: border-box;
  border-radius: 12px;
  margin: auto;
  font-size: 1.125rem;
  line-height: 1.5rem;
  max-width: 956px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.sch-news {
  width: 80%;
  /* height: 280px; */
  /* border: 1px solid #A3A3A3; */
  box-sizing: border-box;
  border-radius: 12px;
  margin: auto;
  font-size: 1.125rem;
  line-height: 1.5rem;
  max-width: 956px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
}

.sch-bt {
  width: 80%;
  margin: auto;
  max-width: 956px;
  padding: 0 5px;
}

.schitem {
  width: 100%;
  height: 72px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
  margin: 8px 14px;
  justify-content: space-between;
}

.schitem:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
}

@media (min-width: 576px) {
  .col-sm-4 {
    flex: 0 0 30%;
  }
}

@media (max-width: 1029px) {
  .col-sm-4 {
    flex: 0 0 29%;
  }
}

@media (max-width: 700px) {
  .col-sm-4 {
    flex: 0 0 28%;
  }
}

@media (max-width: 567px) {
  .col-sm-4 {
    flex: 0 0 92%;
  }
}

.sch-bt-txt {
  font-size: 1.15rem;
  margin: auto 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.colorarea {
  width: 26.67px;
  height: 72px;
  background: #1DB7BD;
  border-radius: 0 8px 8px 0;
  margin-right: -20px;
  color: #fff;
  padding-left: 5px;
  font-size: 1.15rem;
  line-height: 70px;
  display: inline-table;
}

.colorarea img {
  vertical-align: middle;
  padding-left: 5px;
}

.sear-slider {
  width: 90%;
  box-sizing: border-box;
  border-radius: 12px;
  margin: auto;
  font-size: 1.125rem;
  line-height: 1.5rem;
  max-width: 1150px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
  margin: 40px auto;
}

.sear-slider .slick-dots {
  position: initial;
  margin-top: -20px;
  margin-bottom: -10px;
}

.news-date {
  text-align: right;
  color: #A3A3A3;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.order1 {
  order: -1;
  display: flex;
  flex-direction: column;
  max-width: 350px;
}

.tplogo {
  width: 240px;
  margin-top: -5px;
  order: 1;
}

.cooclogo {
  width: 205px;
  height: 28px;
  margin-top: 24px;
  margin-left: 0;
  order: 2;
}

.footerstd {
  display: block;
}

.footer991 {
  display: none;
}

.f-services {
  display: flex;
  flex-flow: row;
  word-break: keep-all;
  justify-content: space-between;
  max-width: 470px;
  white-space: pre;
}

.f-service {
  font-size: 20px;
  color: #fff;
  line-height: 44px;
}

.f-service:hover {
  color: #fff;
}

.f-icon {
  margin: 24px 24px 24px 0;
  order: 3;
}

.f-appicon {
  margin: 0;
  order: 4;
}

.footer-info {
  justify-content: space-evenly;
}

img {
  vertical-align: top;
}

.f-imgflex {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ci {
  margin-right: 20px;
}

@media (max-width:992px) {
  .hr1, .news, .sch-news, .sear-slider, .sch-bt {
    width: 90%;
  }
  #subs .news-date, #msg .news-date {
    text-align: left;
    padding: 0 8px;
  }
  .order1 {
    order: 3;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 450px;
  }
  .tplogo {
    width: 180px;
  }
  .cooclogo {
    margin-top: 0;
    margin-left: 24px;
  }
  .footerstd {
    display: none;
  }
  .footer991 {
    display: block;
  }
  .f-services {
    margin-top: 0;
    flex-flow: column;
  }
  .f-service {
    line-height: 54px;
  }
  .f-icon {
    order: 5;
  }
  .f-appicon {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

@media (max-width:768px) {
  .footer-info {
    flex-flow: column;
    padding: 24px;
  }
  .order1 {
    max-width: max-content;
    margin-top: 40px
  }
  .ci {
    margin-right: auto;
  }
  .bt-more {
    padding: 24px;
  }
}

@media (max-width:475px) {
  .f-imgflex {
    flex-flow: column;
  }
  .cooclogo {
    margin-top: 24px;
    margin-left: 0;
  }
  .f-icon {
    order: 3;
    margin-top: 24px;
    margin-left: 0;
  }
  .f-appicon {
    margin-top: 0;
  }
}

.udata {
  display: block;
  text-align: center;
  padding: 16px;
}

.udata span {
  margin-left: auto;
  font-size: 1.125rem;
  color: #828282;
  white-space: nowrap;
}

.udata span+span {
  font-size: 1.5rem;
  color: #000000;
  font-weight: 600;
}

.svc-list.listdata {
  height: 110px;
  overflow: hidden;
}

.svc-item.udata:hover {
  box-shadow: 0 0 10px #0002;
  border: 1px solid #e6e6e6;
}

.appicon {
  width: 135px;
}

footer p {
  margin: 0.5rem 0;
  color: rgb(255, 255, 255);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 24px;
}

.amail {
  color: #fff;
}

.amail:hover {
  color: #fff;
}

@media (max-width: 991px) {
  .svc-item.udata {
    width: calc(33% - 20px);
  }
  .appicon {
    width: 107px;
  }
}

@media (max-width:768px) {
  .news {
    font-size: 0.875rem;
  }
  .news-date {
    text-align: left;
    padding: 0;
  }
  .udata span {
    font-size: 1rem;
  }
  .udata span+span {
    font-size: 1.125rem;
  }
  .svc-item.udata {
    width: calc(50% - 20px);
    margin-right: 20px;
  }
  .svc-list.listdata {
    height: 200px;
  }
}

@media (max-width:576px) {
  .svc-list.listdata {
    display: flex;
  }
}

.footerbg {
  background: linear-gradient(360deg, #248C91 0%, #28A093 100%);
}
.schoolType{
  font-size: 0.825rem;
  font-weight: 400;
}
